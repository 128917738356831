import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "ui/sections/page";

export const Room = () => {
  const { roomId } = useParams<{ roomId: string }>();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // TODO fetch room details. If room does not exist, redirect to home page
    setIsLoading(false);
  }, [roomId]);

  if (isLoading) {
    return <Page>Loading...</Page>;
  }

  return <Page>Room - {roomId}</Page>;
};
