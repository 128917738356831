import { IconContext } from "react-icons";
import { ThemeProvider, styled } from "@mui/material";
import { theme } from "ui";
import { AppRoutes } from "./routes";

const AppContainer = styled("div")`
  display: flex;
  height: 100vh;
`;

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <IconContext.Provider
        value={{
          style: {
            verticalAlign: "middle",
            alignSelf: "center",
            padding: "2px",
          },
        }}
      >
        <AppContainer>
          <AppRoutes />
        </AppContainer>
      </IconContext.Provider>
    </ThemeProvider>
  );
};
