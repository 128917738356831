import React from "react";
import { Box, SxProps } from "@mui/material";

type PageProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

export const Page = ({ sx, children }: PageProps) => {
  return (
    <Box
      sx={{
        ...{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.default",
          p: 1,
          justifyContent: "center",
          alignItems: "center",
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
