import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, TextField, Typography, styled } from "@mui/material";
import { Page } from "ui/sections/page";

export const HomePage = () => {
  const navigate = useNavigate();

  const [isInvalid, setIsInvalid] = useState(false);
  const [roomId, setRoomId] = useState("");

  const validateRoomId = (id = "") => {
    setIsInvalid(id.length !== 0 && id.length !== 6);
  };

  const handleJoinClick = () => {
    if (!roomId.length) {
      setIsInvalid(true);
    } else if (!isInvalid) {
      navigate(`/room/${roomId}`);
    }
  };

  return (
    <Page>
      <StyledStack spacing={{ mobile: 4, tablet: 6 }} sx={{ mt: -12 }}>
        <StyledStack
          spacing={4}
          mb={4}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant={"h1"}
            fontSize={{ mobile: "4rem", tablet: "6.8rem" }}
            fontWeight={500}
          >
            Hand up
          </Typography>
          <Typography
            variant="h4"
            fontSize={{ mobile: "2rem", tablet: "2.4rem" }}
          >
            A tool for effective consultation
          </Typography>
        </StyledStack>
        <StyledStack
          spacing={1.5}
          justifyContent="center"
          alignItems="center"
          sx={{ width: "15rem" }}
        >
          <TextField
            label="Room ID"
            variant="standard"
            size="small"
            error={isInvalid}
            helperText={isInvalid && "Please enter a valid room ID"}
            onBlur={(e) => validateRoomId(e.target.value)}
            onChange={(e) => setRoomId(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{ width: "15rem" }}
            onClick={handleJoinClick}
          >
            Join a room
          </Button>
        </StyledStack>
        <Button variant="contained" color="secondary" sx={{ width: "12rem" }}>
          Create a room
        </Button>
      </StyledStack>
    </Page>
  );
};

const StyledStack = styled(Stack)`
  justify-content: center;
  align-items: center;
  text-align: center;
`;
